//help class
.bg-cover{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   }

.nav{
   position: relative;
   height: 100vh;

  .logo{
    width: rem-calc(800);
  }
  h1{
    color: $white;
    font-weight: 800;
    font-size: rem-calc(65);
    margin-bottom: rem-calc(200);
    @include breakpoint(medium down){
      font-size: rem-calc(35);
      margin-bottom: rem-calc(50);
    }
  }

  }
  .info{
    .cell{
      padding-bottom: rem-calc(15);
      padding-top: rem-calc(15);
    }
    a{
      color: $white;
    }
    h2{
      color: $white;
      font-weight: 500;
      //font-size: rem-calc(45);
      @include breakpoint(medium down){
        font-size: rem-calc(25);
      }
  }
  :hover{
    a{
    color: rgba($white, 0.6);
    }
  }

}

.heroSlider{
  position: absolute;
  height: 100vh;
  width: 100%;
  &::before{
    position:absolute;
    content: '';
    height: 100%;
    width: 100%;
    background-color:rgba($black, 0.6);
  }
}

